<template>

<li class="nav-item" :class="{'menu-open': isMenuExtended}" v-if="(menuItem.type == null || menuItem.type == 'item') && checkRights()" >
    <a
        class="nav-link"
        :class="{'active': isMainActive || isOneOfChildrenActive}"
        @click="handleMainMenuAction"
    >
        <i class="nav-icon" :class="menuItem.icon != null ? menuItem.icon : 'fas fa-fw fa-tachometer-alt'"></i>
        <p>{{ menuItem.name }}</p>
        <i v-if="isExpandable" class="right fas fa-angle-left"></i>
    </a>
    <ul class="nav nav-treeview" v-for="(item,index) in menuItem.children" :key="item.name">
        <li class="nav-item" v-if="checkChildRights(index)">
            <router-link
                :to="item.path"
                class="nav-link"
                exact
                exact-active-class="active"
            >
                <i class="nav-icon" :class="item.icon != null ? item.icon : 'far fa-fw fa-circle'"></i>
                <p>{{ item.name }}</p>
            </router-link>
        </li>
    </ul>
</li>

<li v-else-if="menuItem.type == 'header' && checkHeaderRights()" class="nav-header">{{ menuItem.name }}</li>

<li v-else-if="menuItem.type == 'timeTracking' && checkTimeTracking()" class="nav-item">
    <a
        class="nav-link"
        :class="{'active': isMainActive || isOneOfChildrenActive}"
        @click="handleMainMenuAction"
    >
        <i class="nav-icon" :class="menuItem.icon != null ? menuItem.icon : 'fas fa-fw fa-tachometer-alt'"></i>
        <p>{{ menuItem.name }}</p>
        <i v-if="isExpandable" class="right fas fa-angle-left"></i>
    </a>
</li>

</template>

<script>
export default {
    name: 'app-menu-item',
    props:[
        'menuItem',
    ],

    data(){
        return{
            isMenuExtended: false,
            isExpandable: false,
            isMainActive: false,
            isOneOfChildrenActive: false,
        }
    },

    methods:{

        checkTimeTracking(){
            var state = false;

            if(this.$auth.user().employee != null){
                if(this.$auth.user().employee.useTimeTracking == 1){
                    state = true;
                }
            }

            return state;
        },

        checkHeaderRights(){
            var state = false;
            if('rights' in this.menuItem)
            {
                this.menuItem.rights.forEach(function(item){
                    if(this.$auth.check(item)){
                        state = true
                    }
                },this)
            }
            else{
                state = true;
            }
            return state;
        },

        checkRights(){
            var state = true;
            if('rights' in this.menuItem)
            {
                //Prüfe ob es ein Recht gibt oder mehrere
                if(this.menuItem.rights.length == 1){
                    // Es gibt nur ein Recht
                    state = true;
                    this.menuItem.rights.forEach(function(item){
                        if(!this.$auth.check(item)){
                            state = false
                        }
                    },this)
                }
                else
                {
                    //Es gibt mehrere Rechte
                    state = false;
                    this.menuItem.rights.forEach(function(item){
                        if(this.$auth.check(item)){
                            state = true
                        }
                    },this)
                }
                
            }
            return state;
        },

        checkChildRights(index){
            var state = true;
            if('children' in this.menuItem){
                if('rights' in this.menuItem.children[index])
                {
                    this.menuItem.children[index].rights.forEach(function(item){
                        if(!this.$auth.check(item)){
                            state = false
                        }
                    },this)
                }
            }
            return state;
        },

        handleMainMenuAction() {
            if (this.isExpandable) {
                this.toggleMenu();
                return;
            }
            if(this.$router.currentRoute.path != this.menuItem.path){
                this.$router.replace(this.menuItem.path);
            }
        },

        toggleMenu() {
            this.isMenuExtended = !this.isMenuExtended;
        },

        calculateIsActive(url) {
            this.isMainActive = false;
            this.isOneOfChildrenActive = false;
            if (this.isExpandable) {
                this.menuItem.children.forEach((item) => {
                    if (item.path === url) {
                        this.isOneOfChildrenActive = true;
                        this.isMenuExtended = true;
                    }
                });
            } else if (this.menuItem.path === url) {
                this.isMainActive = true;
            }
            if (!this.isMainActive && !this.isOneOfChildrenActive) {
                this.isMenuExtended = false;
            }
        }
    },

    mounted(){
        this.isExpandable = this.menuItem && this.menuItem.children && this.menuItem.children.length > 0;
        this.calculateIsActive(this.$router.currentRoute.path);
        this.$router.afterEach((to) => {
            this.calculateIsActive(to.path)
            // console.log(to);
        });
        // this.checkRights();
    }
}
</script>

<style>
.nav-item {
    cursor: pointer;
}
</style>