<template>
  <nav
    class="bg-white main-header navbar navbar-expand navbar-light border-bottom"
  >
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button">
          <i class="fas fa-bars"></i>
        </a>
      </li>
      <li class="nav-item dropdown" v-if="$auth.check('categories.display') || $auth.check('settings.display') 
                                                                            || $auth.check('taxes.display') 
                                                                            || $auth.check('stores.display') 
                                                                            || $auth.check('warehouses.display') 
                                                                            || $auth.check('units.display') 
                                                                            || $auth.check('deposits.display') 
                                                                            || $auth.check('variations.display') 
                                                                            || $auth.check('jobs.display') 
                                                                            || $auth.check('commercial_classes.display') 
                                                                            || $auth.check('holidays.period.display')
                                                                            || $auth.check('healthy-claims.display')
                                                                            || $auth.check('settings.manss_blacklist.display')
                                                                            || $auth.check('countries.display')">
        <a id="SettingsSubMenu" href="#" class="dropdown-item dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Settings</a>
        <ul class="border-0 shadow dropdown-menu" aria-labelledby="SettingsSubMenu" style="left: 0px; right:inherit;">
          <!-- <li v-if="$auth.check('categories.display')">
            <router-link to="/categories" class="dropdown-item">Kategorien</router-link>
          </li> -->
          <template v-if="$auth.check('settings.display')">
            <li v-if="$auth.check('taxes.display')">
              <router-link to="/settings/taxes" class="dropdown-item">Steuersätze</router-link>
            </li>
            <li v-if="$auth.check('stores.display')">
              <router-link to="/settings/stores" class="dropdown-item">Filialen</router-link>
            </li>
            <li v-if="$auth.check('warehouses.display')">
              <router-link to="/settings/warehouses" class="dropdown-item">Lager</router-link>
            </li>
            <li v-if="$auth.check('units.display')">
              <router-link to="/settings/units" class="dropdown-item">Grundeinheiten</router-link>
            </li>
            <li v-if="$auth.check('deposits.display')">
              <router-link to="/settings/deposit" class="dropdown-item">Pfand</router-link>
            </li>
            <li v-if="$auth.check('variations.display')">
              <router-link to="/settings/variation" class="dropdown-item">Variationen</router-link>
            </li>
            <li v-if="$auth.check('jobs.display')">
              <router-link to="/settings/jobs" class="dropdown-item">Jobs</router-link>
            </li>
            <li >
              <router-link to="/settings/faqs" class="dropdown-item">FAQs</router-link>
            </li>
            <li v-if="$auth.check('groups.display')">
              <router-link to="/settings/groups" class="dropdown-item">Warengruppen</router-link>
            </li>
            <li v-if="$auth.check('commercial_classes.display')">
              <router-link to="/settings/commercial_classes" class="dropdown-item">Handelsklassen</router-link>
            </li>
            <li v-if="$auth.check('countries.display')">
              <router-link to="/settings/countries" class="dropdown-item">Herkunftsländer</router-link>
            </li>
            <li v-if="$auth.check('healthy-claims.display')">
              <router-link to="/settings/healthy-claims" class="dropdown-item">Healthy Claims</router-link>
            </li>
            <li v-if="$auth.check('settings.manss_blacklist.display')">
              <router-link to="/settings/manss-blacklist" class="dropdown-item">Manss Blacklist</router-link>
            </li>
            <li v-if="$auth.check('holidays.period.display')">
              <router-link to="/settings/holidays" class="dropdown-item">Ferien</router-link>
            </li>
            <li v-if="$auth.check('calendar.display')">
              <router-link to="/settings/calendar" class="dropdown-item">Kalender</router-link>
            </li>
          </template>
        </ul>
      </li>
      <li class="nav-item dropdown" v-if="$auth.check('users.display') || $auth.check('roles.display') || $auth.check('permissions.display') || $auth.check('permission_groups.display') || $auth.check('companies.display') || $auth.check('news.display') || $auth.check('topic.display')">
        <a id="AdministrationSubMenu" href="#" class="dropdown-item dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Admin</a>
        <ul class="border-0 shadow dropdown-menu" aria-labelledby="SettingsSubMenu" style="left: 0px; right:inherit;">
          <li v-if="$auth.check('users.display')">
            <router-link to="/admin/users" class="dropdown-item">Benutzer</router-link>
          </li>
          <li class="dropdown-submenu dropdown-hover">
            <a id="RolesSubmenu" href="#" role="button" v-if="$auth.check('roles.display') || $auth.check('permissions.display') || $auth.check('permission_groups.display')" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="dropdown-item dropdown-toggle">Berechtigungen</a>
            <ul aria-labelledby="RolesSubmenu" class="border-0 shadow dropdown-menu">
              <li v-if="$auth.check('roles.display')">
                <router-link to="/admin/roles" class="dropdown-item">Roles</router-link>
              </li>
              <li v-if="$auth.check('permissions.display')">
                <router-link to="/admin/permissions" class="dropdown-item">Permissions</router-link>
              </li>
              <li v-if="$auth.check('permission_groups.display')">
                <router-link to="/admin/groups" class="dropdown-item">Groups</router-link>
              </li>
            </ul>
          </li>
          <!-- <li v-if="$auth.check('companies.display')">
            <router-link :to="{name: 'companies.index'}" class="dropdown-item">Firmen</router-link>
          </li> -->
          <!-- <li v-if="$auth.check('greetingcard.display')">
            <router-link :to="{name: 'greetingcards.index'}" class="dropdown-item">Grußkarten</router-link>
          </li> -->
          <li v-if="$auth.check('giftwrapper.display')">
            <router-link :to="{name: 'giftwrappers.index'}" class="dropdown-item">Geschenkkörbe</router-link>
          </li>
          <li v-if="$auth.check('car.display')">
            <router-link :to="{name: 'cars.index'}" class="dropdown-item">Fahrzeuge</router-link>
          </li>
          <li v-if="$auth.check('topic.display')">
              <router-link to="/admin/topics" class="dropdown-item">News Kategorien</router-link>
          </li>
          <!-- <li class="dropdown-submenu dropdown-hover" v-if="$auth.check('news.display') || $auth.check('topic.display')">
            <a id="RolesSubmenu" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="dropdown-item dropdown-toggle">Neuigkeiten</a>
            <ul aria-labelledby="RolesSubmenu" class="border-0 shadow dropdown-menu">
              <li v-if="$auth.check('news.display')">
                <router-link to="/admin/news" class="dropdown-item">Alle News</router-link>
              </li> 
              <li v-if="$auth.check('topic.display')">
                <router-link to="/admin/topics" class="dropdown-item">Kategorien</router-link>
              </li>
            </ul>
          </li> -->
        </ul>
      </li>
      <li class="nav-item ml-2" style="margin-top: -0.25rem;" v-if="$auth.check('companies.display')">
        <router-link :to="{name: 'companies.index'}" class="btn"><i class="fas fa-store"></i>&nbsp;&nbsp;Shops verwalten</router-link>
      </li>
    </ul>

    <ul class="ml-auto navbar-nav">
      
      
        

      <li class="nav-item dropdown user-menu">
        <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">
          <img
            src="https://picsum.photos/300/300"
            class="user-image img-circle elevation-2"
            alt=""
          />
          <span class="d-none d-md-inline">
            {{ this.$auth.user().first_name}} {{this.$auth.user().last_name }}
          </span>
        </a>

        <ul class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <li class="user-header bg-primary">
            <img
              src="https://picsum.photos/300/300"
              class="img-circle elevation-2"
              alt=""
            />
            <p class="">
              {{ this.$auth.user().first_name}} {{this.$auth.user().last_name }}
              <small>{{ this.$auth.user().position }}</small>
            </p>
          </li>

          <li class="user-footer">
            <router-link to="/profile" class="btn btn-default btn-flat">
              <i class="fa fa-fw fa-user"></i>
              Profile
            </router-link>
            <a class="float-right btn btn-default btn-flat " href="#" @click="logout">
              <i class="fa fa-fw fa-power-off"></i>
              Log Out
            </a>
          </li>
        </ul>
      </li>
      <!-- Messages Dropdown Menu -->
      
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Header",
  methods: {
    logout() {
      this.$auth.logout({
        makeRequest: true,
        redirect: {name: 'Login'},
      })
    },

    toggleMenuSidebar: function() {
      if (document.body.classList.contains("sidebar-open")) {
        document.body.classList.remove("sidebar-open");
        document.body.classList.add("sidebar-collapse");
      } else if (document.body.classList.contains("sidebar-collapse")) {
        document.body.classList.remove("sidebar-collapse");
        document.body.classList.add("sidebar-open");
      }
      //console.log("Test");
    },
    logOut: function() {
      this.$store.dispatch("logOut");
    }
  }
};
</script>

<style>
.nav-link {
  cursor: pointer;
}
</style>
