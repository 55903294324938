<template>
<div>
  <div class="text-center" v-if="loading" style="display: flex; width: 100%; height: 100%; position:fixed; top:0; z-index:9999; cursor: pointer; background-color: rgba(0,0,0,0.7);">
    <div class="spinner-border text-light" style="width: 6rem; height: 6rem; margin: auto;" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div class="wrapper">
    <Header></Header>
    <SidebarMenu></SidebarMenu>

    <div class="content-wrapper"
    >
      <!-- <div class="content" :style="$route.name == 'boards.details' ? 'height: 100%; overflow-x: auto; overflow-y: hidden; height:100%;' : ''"> -->
      <div class="content">
          <router-view></router-view>
      </div>
    </div>

    <Footer></Footer>

    <!-- <aside class="control-sidebar control-sidebar-dark">
      <div class="p-3">
        Content of the sidebar goes here
      </div>
    </aside> -->
    <!-- <div id="sidebar-overlay"></div> -->
  </div>
</div>
</template>

<script>
import Header from "./../components/main/Header";
import SidebarMenu from "./../components/main/SidebarMenu";
import Footer from "./../components/main/Footer";
import {mapState} from 'vuex';

export default {
  name: "Main",
  components: {
    Header,
    SidebarMenu,
    Footer
  },
  
  computed: {
    ...mapState('loader', ['loading'])
  },
};
</script>

<style scoped></style>
